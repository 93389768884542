<template>
  <div class="weChatShare"></div>
</template>

<script>
import { Dialog } from "vant";
export default {
  name: 'weChatShare',
  mounted() {
    Dialog.alert({
      message: '即将前往"智汇学堂"进行分享',
    }).then(() => {
      this.shareEvent();
    });
  },
  methods: {
    shareEvent() {
      let type = this.$store.state.deviceType;
      console.log(type)
      let id = this.$route.query.id;
      if(type == 1 || type == 4) {
        window.location.href = `com.czh.zhxt.app://noticeDetails?id=${id}`;
      } else if(type == 2 || type == 5) {
        window.location.href = `android://com.czh.zhxt/open?id=${id}`;
      }
    }
  }
}
</script>

<style lang="scss">
  .weChatShare {
    text-align: center;
    margin-top: 100px;
  }
</style>
